<template>
    <div>
      <div class="row">
        <div class="col-md-12">
          <KTPortlet v-bind:title="title">
            <template v-slot:body>
              <v-form ref="form" @submit.prevent="submitForm">
                <div class="row">
                  <div class="col-6">
                    <v-text-field
                      ref="searchKeyword"
                      v-model="form.searchKeyword"
                      :disabled="datatable.loading"
                      :label="$t('SHARED.KEYWORD')"
                      :hint="$t('REPORT.USE_BELL_POINT.KEYWORD_HINT')"
                      prepend-icon="mdi-file-document-box-search-outline"
                    ></v-text-field>
                  </div>
                  <!-- startDate -->
                  <div class="col-3">
                    <v-dialog
                      ref="dialogStart"
                      v-model="form.startDateModel"
                      :disabled="form.loading"
                      :return-value.sync="form.startDate"
                      persistent
                      width="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          v-model="form.startDate"
                          :disabled="form.loading"
                          :label="$t('REPORT.USE_BELL_POINT.START_DATE')"
                          :rules="form.startDateRules"
                          prepend-icon="event"
                          readonly
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="form.startDate"
                        locale="th"
                        :disabled="form.loading"
                        scrollable
                      >
                        <v-spacer></v-spacer>
                        <v-btn
                          text
                          color="default"
                          @click="form.startDateModel = false"
                          >{{ $t("SHARED.CANCEL_BUTTON") }}</v-btn
                        >
                        <v-btn
                          text
                          color="primary"
                          @click="$refs.dialogStart.save(form.startDate)"
                          >{{ $t("SHARED.CHOOSE_BUTTON") }}</v-btn
                        >
                      </v-date-picker>
                    </v-dialog>
                  </div>
  
                  <!-- endDate -->
                  <div class="col-3">
                    <v-dialog
                      ref="dialogEnd"
                      v-model="form.endDateModel"
                      :disabled="form.loading"
                      :return-value.sync="form.endDate"
                      persistent
                      width="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          v-model="form.endDate"
                          :disabled="form.loading"
                          :label="$t('REPORT.USE_BELL_POINT.END_DATE')"
                          :rules="form.endDateRules"
                          prepend-icon="event"
                          readonly
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="form.endDate"
                        locale="th"
                        :disabled="form.loading"
                        scrollable
                      >
                        <v-spacer></v-spacer>
                        <v-btn
                          text
                          color="default"
                          @click="form.endDateModel = false"
                          >{{ $t("SHARED.CANCEL_BUTTON") }}</v-btn
                        >
                        <v-btn
                          text
                          color="primary"
                          @click="$refs.dialogEnd.save(form.endDate)"
                          >{{ $t("SHARED.CHOOSE_BUTTON") }}</v-btn
                        >
                      </v-date-picker>
                    </v-dialog>
                  </div>
  
                  <div class="col-3">
                    <v-autocomplete
                      v-model="form.memberClassCode"
                      :disabled="datatable.loading"
                      :items="form.memberClassCodeItems"
                      hide-no-data
                      hide-selected
                      item-text="text"
                      item-value="value"
                      :label="$t('REPORT.CUSTOMER_DETAIL.MEMBER_CLASS_CODE')"
                      :placeholder="
                        $t('SHARED.START_TYPING_TO_SEARCH')"
                      prepend-icon="mdi-database-search"
                      return-object
                      clearable
                    ></v-autocomplete>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12">
                    <v-btn
                      :disabled="datatable.loading"
                      color="success"
                      class="mr-4"
                      tile
                      type="submit"
                    >
                      <v-icon v-if="!datatable.loading" left
                        >mdi-database-search</v-icon
                      >
                      <v-icon v-if="datatable.loading" left
                        >fa fa-spinner fa-spin</v-icon
                      >
                      {{ $t("SHARED.SEARCH_BUTTON") }}
                    </v-btn>
                    <v-btn
                      :disabled="datatable.loading"
                      color="default"
                      class="mr-4"
                      type="reset"
                      tile
                      @click.prevent="resetForm"
                    >
                      <v-icon left>mdi-eraser</v-icon>
                      {{ $t("SHARED.RESET_BUTTON") }}
                    </v-btn>
                    <v-btn
                      :disabled="datatable.loading"
                      color="warning"
                      class="mr-4"
                      tile
                      @click.prevent="Export"
                    >
                      <v-icon v-if="!datatable.loading" left
                        >fas fa-file-export</v-icon
                      >
                      <v-icon v-if="datatable.loading" left
                        >fa fa-spinner fa-spin</v-icon
                      >
                      {{ $t("SHARED.EXPORT_BUTTON") }}
                    </v-btn>
                  </div>
                </div>
              </v-form>
  
              <v-divider class="my-4"></v-divider>
  
              <v-subheader>
                <v-icon left>mdi-table-search</v-icon>
                {{ $t("SHARED.SEARCH_RESULT") }}
                <v-spacer></v-spacer>
              </v-subheader>
  
              <v-data-table
                :headers="datatable.headers"
                :items="datatable.items"
                :loading="datatable.loading"
                :options.sync="datatable.options"
                :server-items-length="datatable.total"
                :footer-props="{
                  'items-per-page-options': [30, 50, 100],
                }"
                multi-sort
              ></v-data-table>
  
              <v-dialog v-model="datatable.loading" persistent width="300">
                <v-card>
                  <v-card-title class="headline">{{
                    $t("SHARED.PLEASE_WAIT")
                  }}</v-card-title>
                  <v-card-text>
                    <p>{{ $t("SHARED.PROCESSING") }}</p>
                    <v-progress-linear
                      indeterminate
                      color="amber lighten-3"
                      class="mb-3"
                    ></v-progress-linear>
                  </v-card-text>
                </v-card>
              </v-dialog>
            </template>
          </KTPortlet>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import ApiService from "@/common/api.service";
  import JwtService from "@/common/jwt.service";
  import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
  import KTPortlet from "@/views/partials/content/Portlet.vue";
  
  export default {
    components: {
      KTPortlet,
    },
    data() {
      return {
        form: {
          searchKeyword: "",
          startDate: null,
          endDate: null,
          endDateModel: false,
          startDateModel: false,
          valid: true,
          dialog: false,
          loading: false,
          errors: [],
          name: "",
          memberClassCode: null,
          memberClassCodeItems: [
            {
              text: this.$t("SYS_VARIABLE.MEMBER_CLASS_NORMAL"),
              value: "MEMBER_CLASS_NORMAL",
            },
            {
              text: this.$t("SYS_VARIABLE.MEMBER_CLASS_GOLD"),
              value: "MEMBER_CLASS_GOLD",
            },
          ],
        },
        datatable: {
          headers: [
            {
              text: this.$t("REPORT.USE_BELL_POINT.SALE_DATE"),
              value: "saleDateString",
              align: "center",
            },
            {
              text: this.$t("REPORT.USE_BELL_POINT.CARD_NUMBER"),
              value: "cardNumber",
              align: "center",
            },
            {
              text: this.$t("REPORT.USE_BELL_POINT.MEMBER_CLASS_CODE"),
              value: "systemVariableName",
              align: "center",
            },
            {
              text: this.$t("REPORT.USE_BELL_POINT.FIRST_NAME"),
              value: "firstName",
              align: "center",
            },
            {
              text: this.$t("REPORT.USE_BELL_POINT.LAST_NAME"),
              value: "lastName",
              align: "center",
            },
            {
              text: this.$t("REPORT.USE_BELL_POINT.BRANCH_CODE"),
              value: "branchCode",
              align: "center",
            },
            {
              text: this.$t("REPORT.USE_BELL_POINT.BRANCH_NAMETH"),
              value: "branchName",
            },
            {
              text: this.$t("REPORT.USE_BELL_POINT.SALE_NUM"),
              value: "saleNum",
            },
            {
              text: this.$t("REPORT.USE_BELL_POINT.TRANS_POINT"),
              value: "transferPoint",
            },
            {
              text: this.$t("REPORT.USE_BELL_POINT.SUM_POINT"),
              value: "sumPoint",
            },
            {
              text: this.$t("REPORT.USE_BELL_POINT.VALID_POINT"),
              value: "validPoint",
            },
            {
              text: this.$t("REPORT.USE_BELL_POINT.REDEEM_POINT"),
              value: "redeemedPoint",
            },
            {
              text: this.$t("REPORT.USE_BELL_POINT.BALANCE_POINT"),
              value: "balancePoint",
            },
            {
              text: this.$t("REPORT.USE_BELL_POINT.COUPON_NAME"),
              value: "couponSeriesName",
            }
          ],
          items: [],
          total: 0,
          loading: true,
          options: {
            sortBy: ["saleDateString"],
            sortDesc: [true],
            itemsPerPage: 30,
          },
        },
      };
    },
    methods: {
      submitForm() {
        this.getDataFromApi().then((data) => {
          this.datatable.total = data.total;
          this.datatable.items = data.items;
        });
      },
      resetForm() {
        this.form.searchKeyword = "";
        this.form.memberClassCode = null;
        var s = new Date();
        s.setDate(1);
        this.form.startDate = s.toISOString().substr(0, 10);
        var e = new Date();
        var year = new Date().getFullYear();
        var month = new Date().getMonth();
        month = month == 12 ? 0 : month + 1;
        var day = new Date(year, month, 0).getDate();
        e.setDate(day);
        this.form.endDate = e.toISOString().substr(0, 10);
        this.submitForm();
      },
      getDataFromApi() {
        this.datatable.loading = true;
        return new Promise((resolve) => {
          ApiService.setHeader();
          ApiService.post("/Api/Report/UseBellPoint/Search", {
            GroupBy: this.datatable.options.groupBy,
            GroupDesc: this.datatable.options.groupDesc,
            ItemsPerPage: this.datatable.options.itemsPerPage,
            Page: this.datatable.options.page,
            SortBy: this.datatable.options.sortBy,
            SortDesc: this.datatable.options.sortDesc,
            SearchKeyword: this.form.searchKeyword,
            StartDate: this.form.startDate,
            EndDate: this.form.endDate,
            LevelMember: this.form.memberClassCode
              ? this.form.memberClassCode.value
              : null,
          })
            .then(({ data }) => {
              if(data.total > 0){
                data.items.forEach((value) => {
                  value.id = value.id + (Math.random() + 1).toString(36).substring(7); // Warning Dup key error
                });
              }
              resolve({
                items: data.items,
                total: data.total,
              });
            })
            .finally(() => {
              this.datatable.loading = false;
            });
        });
      },
      Export() {
        var SortBy = "";
        var SortDesc = "";

        this.datatable.options.sortBy.forEach((val) => {
          SortBy += '&SortBy='+val;
        });
        this.datatable.options.sortDesc.forEach((val) => {
          SortDesc += '&SortDesc='+val;
        });
        var LevelMember = this.form.memberClassCode
          ? this.form.memberClassCode.value
          : "";
        window.open(
          "/Files/Export/UseBellPoint?searchKeyword=" +
            this.form.searchKeyword +
            "&LevelMember=" + 
            LevelMember +
            "&StartDate=" +
            this.form.startDate +
            "&EndDate=" +
            this.form.endDate +
            SortBy + SortDesc +
            "&token=" +
            this.token,
          "_blank"
        );
      },
    },
    mounted() {
      this.$store.dispatch(SET_BREADCRUMB, [
        { title: this.$t("MENU.REPORT.SECTION"), route: "/Report" },
        { title: this.$t("MENU.REPORT.USE_BELL_POINT") },
      ]);
    },
    computed: {
      title() {
        return this.$t("MENU.REPORT.USE_BELL_POINT_SEARCH");
      },
      token() {
        return JwtService.getToken();
      },
    },
    watch: {
      "datatable.options": {
        handler() {
          if (!this.form.startDate) {
            var s = new Date();
            s.setDate(1);
            this.form.startDate = s.toISOString().substr(0, 10);
          }
  
          if (!this.form.endDate) {
            var e = new Date();
            var year = new Date().getFullYear();
            var month = new Date().getMonth();
            month = month == 12 ? 0 : month + 1;
            var day = new Date(year, month, 0).getDate();
            e.setDate(day);
            this.form.endDate = e.toISOString().substr(0, 10);
          }
  
          this.getDataFromApi().then((data) => {
            this.datatable.total = data.total;
            this.datatable.items = data.items;
          });
        },
        deep: true,
      },
    },
  };
  </script>
  <style lang="scss" scoped></style>